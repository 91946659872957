import { useState } from "react";
import EmergencyWaterDamage from "./images/Emergency-water-damage.jpg";
import FloodRestoration from "./images/flood-restoration.jpg";
import WorkingWithInsurance from "./images/working-with-insurance.jpg";
import WaterExtractionAndDrying from "./images/water-extraction-and-drying.jpg";
import ResidentialWaterDamage from "./images/residential-water-damage.jpg";
import CommercialWaterDamage from "./images/commercial-water-damage.jpg";
import BasementFlooding from "./images/basement-flooding.jpg";
import "../styles/Collapsable.css";

function Collapsable() {
  const [openTab, setOpenTab] = useState(null); // Tracks the currently open tab

  const ToggleCollapse = (tabName) => {
    setOpenTab((prevTab) => (prevTab === tabName ? null : tabName)); // Toggle the tab
  };

  return (
    <div className="collapsable">
      <div
        className="collapsable-tab"
        onClick={() => ToggleCollapse("waterDamage")}
      >
        <span className="collapsable-arrow"></span>
        <span className="collapsable-title">Emergency Water Damage</span>
      </div>
      <div
        className={`collapsable-tab-info ${
          openTab === "waterDamage" ? "visible" : ""
        }`}
      >
        <img className="tab-image" src={EmergencyWaterDamage}></img>
        <h2 className="tab-htext">
          Richmond Emergency Water Damage Restoration
        </h2>
        <p className="tab-ptext">
          When a water emergency strikes, you need help fast. The professional
          water damage restoration specialists at E&D Restoration have years of
          experience helping property owners fix water damage issues quickly and
          correctly. We understand that water damage emergencies are impossible
          to predict, which is why we are available 24 hours a day, 7 days a
          week to help you with your Richmond water damage problem.
        </p>
        <h2 className="tab-blue">
          We're available to assist in your water emergency 24/7!
        </h2>
        <p className="tab-ptext">
          It's important to act quickly when your home or business is affected
          by severe water damage from a flood or other major water issue. The
          faster you take action, the more likely you may be to prevent even
          further damage. Our team will quickly come to your property to assess
          the situation and develop a solution to minimize the long term damage
          to your property.
        </p>
        <p className="tab-ptext">
          Whether you experience a major basement flood in the middle of the
          night or you wake up to find the washer has leaked all over the
          kitchen floor, E&D Restoration is there for you. We have team members
          standing by to assist you any time, day or night. We understand how
          stressful a flood can be, so let our team take the stress out of water
          clean up and call us today!
        </p>
        <h2 className="tab-footer">
          If you're a property owner who has experienced a flood or other water
          disaster, call <a href="tel:(804) 433-5039">804-433-5039</a> now for
          immediate assistance with your Richmond water damage issue.
        </h2>
      </div>
      <div
        className="collapsable-tab"
        onClick={() => ToggleCollapse("floodRestoration")}
      >
        <span className="collapsable-arrow"></span>
        <span className="collapsable-title">Flood Restoration</span>
      </div>
      <div
        className={`collapsable-tab-info ${
          openTab === "floodRestoration" ? "visible" : ""
        }`}
      >
        <img className="tab-image" src={FloodRestoration}></img>
        <h2 className="tab-htext">Richmond Flood Restoration</h2>
        <p className="tab-ptext">
          If you own a building that has suffered a flood, the water restoration
          experts at E&D Restoration are standing by ready to help. We pride
          ourselves in responding to flood damage as soon as possible, as quick
          action is often critical in order to prevent serious structural issues
          that often accompany severe water damage.
        </p>
        <h2 className="tab-blue">Basement Flood Clean Up</h2>
        <p className="tab-ptext">
          While any area of your building can be affected by flooding, much of
          the flood restoration performed deals with flooded basements. Because
          water heaters, sump pumps, and washers often reside in basements,
          basement floods are the most common type of flood we are called to
          clean up. Plus, when there is run off from heavy rainfall, for
          example, the basement is usually the first place to accumulate most of
          the water.
        </p>
        <p className="tab-ptext">
          There are unique dangers posed by wet or flooded basements, so it's
          important to call a flood damage professional as soon as possible if
          you have experienced basement flooding at your home or office.
          Potential structural damage, mold and mildew growth, toxic or
          contaminated water, electrical problems, and many other potential
          problems can be a result of flooding.
        </p>
        <h2 className="tab-blue">What do I do if I experience a flood?</h2>
        <p className="tab-ptext">
          The key to any successful flood restoration is to first eliminate the
          source of the water and to then remove all traces of standing water.
          At E&D Restoration, we use the most advanced technology to dry out
          your home or business after a flood. To best prevent mildew, mold, or
          other moisture-related issues, it is important to properly extract all
          standing water and ensure its surroundings are dry. Our team of water
          restoration specialists have years of experience when it comes to
          flood restoration and will work hard to return your building to its
          pre-disaster state as quickly and safely as possible!
        </p>
        <h2 className="tab-blue">
          Who do I call for help with flood clean up?
        </h2>
        <p className="tab-ptext">
          When dealing with any water emergency, it's important to choose a
          licensed, experienced restoration company. The E&D Restoration team
          has years of industry experience and is fully licensed and insured.
          We're available 24 hours a day, 7 days a week and will even work
          directly with your insurance company to help take the stress out of
          the claims process.
        </p>
        <h2 className="tab-footer">
          If you're a property owner who has experienced a flood or other water
          disaster, call <a href="tel:(804) 433-5039">804-433-5039</a> now for
          immediate assistance with your Richmond water damage issue.
        </h2>
      </div>
      <div
        className="collapsable-tab"
        onClick={() => ToggleCollapse("WorkingWithInsurance")}
      >
        <span className="collapsable-arrow"></span>
        <span className="collapsable-title">Working with Insurance</span>
      </div>
      <div
        className={`collapsable-tab-info ${
          openTab === "WorkingWithInsurance" ? "visible" : ""
        }`}
      >
        <img className="tab-image" src={WorkingWithInsurance}></img>
        <h2 className="tab-htext">Working With Your Insurance Company</h2>
        <p className="tab-ptext">
          When you've experienced a flood or other water disaster, the last
          thing you want to do is deal with your property owner's insurance. At
          E&D Restoration, we are experienced in working with insurance
          companies and know how to help you make sure you get the coverage you
          pay for!
        </p>
        <h2 className="tab-blue">
          How can E&D Restoration help with property insurance claims?
        </h2>
        <p className="tab-ptext">
          Many common causes of water damage are often covered by property
          owner's insurance, including floods from bursting or breaking pipes,
          dishwasher leaks, water heater leaks, washing machines leaks, toilet
          overflows, and more. But some other damage, such as flooding from a
          major storm, might not offer any coverage at all. Navigating through
          the tricky language of your insurance policy can add unnecessary
          stress to you as you work to recover your property after severe water
          damage.
        </p>
        <p className="tab-ptext">
          At E&D Restoration, we have experienced staff members dedicated to
          ensuring you get the most coverage out of your insurance policy. We
          bill your insurance company directly, which means you never have to
          worry about filing the correct forms or staying on hold trying to get
          ahold of your insurance representative. We aim to take every bit of
          stress away during this trying time and will do everything we can to
          move the claims process along.
        </p>
        <p className="tab-ptext">
          With years of experience in water damage restoration, the experts at
          E&D Restoration are familiar with various types of insurance coverage.
          We will work with your insurance company to help get as much of your
          restoration project covered as possible. Let the pros at E&D
          Restoration help make this stressful process easier on you and restore
          your Richmond area home or business to its pre-flood condition.
        </p>
        <h2 className="tab-footer">
          If you've experienced water damage in Richmond, let E&D Restoration
          handle everything from flood clean up through filing your insurance
          claims. Call <a href="tel:(804) 433-5039">804-433-5039</a> now for
          help with all your water restoration needs!
        </h2>
      </div>
      <div
        className="collapsable-tab"
        onClick={() => ToggleCollapse("WaterExtractionAndDrying")}
      >
        <span className="collapsable-arrow"></span>
        <span className="collapsable-title">Water Extraction & Drying</span>
      </div>
      <div
        className={`collapsable-tab-info ${
          openTab === "WaterExtractionAndDrying" ? "visible" : ""
        }`}
      >
        <img className="tab-image" src={WaterExtractionAndDrying}></img>
        <h2 className="tab-htext">Richmond Water Removal & Drying</h2>
        <p className="tab-ptext">
          Whether from a burst pipe or water heater leak, standing water at your
          home or office is not to be taken lightly! If you own a property that
          has significant water from a flood, E&D Restoration can help you
          return your building to a safe, comfortable environment as quickly and
          safely as possible.{" "}
        </p>
        <h2 className="tab-blue">
          Expert Water Extraction & Structural Drying in Richmond
        </h2>
        <p className="tab-ptext">
          The key to a successful water damage restoration is quickly removing
          all standing water so your structure can dry. Once the water is
          extracted, our team will go to work drying your structure with high
          grade fans and other drying equipment If left untreated, water damage
          can cause problems, such as mold or mildew, which are not only harmful
          to the structural integrity of your building, but can have serious
          health consequences for friends, family, and guests.
        </p>
        <p className="tab-ptext">
          At E&D Restoration, we are properly licensed to handle even the most
          severe water removal jobs. We use the latest in water extraction and
          drying technology to make sure every bit of water is removed and your
          building is no longer at risk. Our team follows the highest industry
          standards and will work hard to return your home or office to its
          pre-disaster state.
        </p>
        <h2 className="tab-footer">
          Contact us today at <a href="tel:(804) 433-5039">804-433-5039</a> so
          we can help you with Richmond water extraction and structural drying
          services.
        </h2>
      </div>
      <div
        className="collapsable-tab"
        onClick={() => ToggleCollapse("ResidentialWaterDamage")}
      >
        <span className="collapsable-arrow"></span>
        <span className="collapsable-title">Residential Water Damage</span>
      </div>
      <div
        className={`collapsable-tab-info ${
          openTab === "ResidentialWaterDamage" ? "visible" : ""
        }`}
      >
        <img className="tab-image" src={ResidentialWaterDamage}></img>
        <h2 className="tab-htext">
          Richmond Residential Water Damage Restoration
        </h2>
        <p className="tab-ptext">
          Residential water damage is usually unexpected and can be caused by a
          variety of things, including broken pipes, washing machines, leakage
          and overflows from water heaters, refrigerators, roof leaks,
          dishwashers, and more. At E&D Restoration, we understand that it is
          very discomforting to see your home flooded and are here to help you
          quickly return your home to a safe, dry environment.
        </p>
        <p className="tab-ptext">
          We are experts in residential water damage issues and are committed to
          providing prompt, thorough service. Our highly trained water damage
          restoration technicians will make you feel at ease with their
          experience and knowledge to handle nearly any kind of residential
          water damage problem.
        </p>
        <p className="tab-ptext">
          To restore your home to its pre-damage condition, correct drying of
          the affected areas is essential. This will ensure that dangerous
          substances, such as mildew and mold, can't grow. At E&D Restoration,
          we will not only quickly extract water from your home, but our highly
          trained water damage technicians will make sure that your home is
          properly dried out to prevent any future problems with mold or mildew.{" "}
        </p>
        <p className="tab-ptext">
          At E&D Restoration, we are here to ensure that your home is restored
          as quickly and as professionally as possible. In order to minimize the
          damage and restore your home to pre-flood conditions, our highly
          trained restoration professionals are ready to act quickly to solve
          your water damage issue. Best of all, we work directly with your
          insurance company to help you save time on your property owners
          insurance claim.
        </p>
        <h2 className="tab-footer">
          Contact today at <a href="tel:(804) 433-5039">804-433-5039</a> so we
          can assist you with your Richmond water damage issue.
        </h2>
      </div>
      <div
        className="collapsable-tab"
        onClick={() => ToggleCollapse("CommercialWaterDamage")}
      >
        <span className="collapsable-arrow"></span>
        <span className="collapsable-title">Commercial Water Damage</span>
      </div>
      <div
        className={`collapsable-tab-info ${
          openTab === "CommercialWaterDamage" ? "visible" : ""
        }`}
      >
        <img className="tab-image" src={CommercialWaterDamage}></img>
        <h2 className="tab-htext">
          Richmond Commercial Water Damage Restoration
        </h2>
        <p className="tab-ptext">
          At E&D Restoration, we understand that water damage and flooding can
          do untold damage to your business. Floods are the single most costly
          disaster in the United States, causing over a billion dollars in
          damage per year, and can be especially damaging when they hinder or
          stop your company's regular business.
        </p>
        <p className="tab-ptext">
          If your business has experienced a recent flood, the professionals at
          E&D Restoration can help. Give us a call as soon as possible after the
          flood or water damage occurs, as often times flooding and water damage
          need to be addressed as soon as possible to prevent potential
          permanent structural damage or harmful mold and mildew growth.
        </p>
        <p className="tab-ptext">
          The E&D Restoration team is committed to using modern drying
          technology and water damage repair techniques to restore your business
          to its pre-flood condition. We pride ourselves in our quick response
          time and our highly trained water damage restoration professionals
          will make you feel at ease about your commercial water damage issue.
          We have the experience and the know how to handle any kind of
          commercial water damage problem, from burst pipes to water heater
          leaks.
        </p>
        <h2 className="tab-footer">
          Contact us today at <a href="tel:(804) 433-5039">804-433-5039</a> so
          we can help you with your Richmond commercial water damage issue.
        </h2>
      </div>
      <div
        className="collapsable-tab"
        onClick={() => ToggleCollapse("BasementFlooding")}
      >
        <span className="collapsable-arrow"></span>
        <span className="collapsable-title">Basement Flooding</span>
      </div>
      <div
        className={`collapsable-tab-info ${
          openTab === "BasementFlooding" ? "visible" : ""
        }`}
      >
        <img className="tab-image" src={BasementFlooding}></img>
        <h2 className="tab-htext">Richmond Basement Flood Clean Up</h2>
        <p className="tab-ptext">
          One of the most common Richmond water damage issues is due to basement
          flooding. Sump pumps, main plumbing pipes, and clothes washers usually
          live in the basement of most homes. Following a storm, runoff water
          has the potential to end up causing a basement flood for some as well.
          When you experience a basement flood, call the experts at E&D
          Restoration for professional basement flood removal services.
        </p>
        <p className="tab-ptext">
          When you experience a basement flood, properly water extraction and
          structural dryout is critical to prevent future problems. Removing all
          traces of water from your basement flood helps to prevent the growth
          of black mold, mildew, and other bacteria, all of which pose serious
          health concerns to your family.E&D Restoration has years of experience
          and training to make sure each step is performed thoroughly and
          correctly the first time.
        </p>
        <p className="tab-ptext">
          By hiring E&D Restoration for your basement flood clean up project,
          you can rest easy knowing licensed, insured, professionals will work
          tirelessly to return your home to its pre-flood condition. We use the
          latest technology and adhere to the highest industry standards to
          ensure a successful restoration project.
        </p>
        <h2 className="tab-footer">
          Call us today at <a href="tel:(804) 433-5039">804-433-5039</a> for
          Richmond basement flood clean up service.
        </h2>
      </div>
    </div>
  );
}

export default Collapsable;
