import "../styles/Reviews.css";
import googleIcon from "./images/google-review-icon2.jpg";

function Reviews() {
  return (
    <div className="reviews" id="contact">
      <div className="reviews-left">
        <h1>We love our customers - they love us!</h1>
        <div className="customer-review">
          <img src={googleIcon} height="120" alt="google icon"></img>
        </div>
      </div>
      <div className="reviews-right">
        <div className="rr-text">Trustworthy and professional</div>
        <div className="reviews-right-box">
          <h2>CERTIFIED EXPERTS</h2>
        </div>
        <div className="reviews-right-box">
          <h2>FAST, FRIENDLY SERVICE</h2>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
