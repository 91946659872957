import superiorService from "./images/superior-service-icon.png";
import quickResponse from "./images/quick-response-icon.png";
import trustedExpert from "./images/trusted-experts-icon.png";
import ourServices from "./images/our-services.jpg";
import callNow from "./images/call-now-service.png";
import "../styles/Services.css";

function Services() {
  return (
    <div className="services" id="services">
      <div className="services-call">
        <h1>
          Call{" "}
          <a href="tel:(804) 433-5039" style={{ color: "red" }}>
            (804) 433-5039
          </a>{" "}
          for Fast, Affordable Water Damage Restoration Services.
        </h1>
      </div>
      <div className="services-icons">
        <div className="services-icons-child">
          <img src={superiorService} height="60" alt="superiorService"></img>
          <p>Superior Service</p>
          <div className="line"></div>
        </div>
        <div className="services-icons-child">
          <img src={quickResponse} height="60" alt="quickResponse"></img>
          <p>Quick Response</p>
          <div className="line"></div>
        </div>
        <div className="services-icons-child">
          <img src={trustedExpert} height="60" alt="trustedExpert"></img>
          <p>Trusted Experts</p>
          <div className="line"></div>
        </div>
      </div>
      <div className="our-services-container">
        <img src={ourServices} alt="ourServices"></img>
        <div className="our-services-textbox">
          <p>
            At E&D Restoration, we offer comprehensive water damage restoration
            services for both residential and commercial properties in Richmond.
            Our team of IICRC-certified professionals is equipped with
            state-of-the-art technology and extensive experience to handle any
            water damage situation, from minor leaks to major flooding. We are
            committed to restoring your property efficiently and effectively,
            ensuring minimal disruption to your daily life or business
            operations.
          </p>
          <p>
            We understand that water damage emergencies can occur at any time,
            which is why we provide 24-hour emergency services to address your
            needs promptly. Our quick response ensures that we can mitigate
            damage and start the restoration process as soon as possible,
            helping to prevent further complications and reduce repair costs. No
            matter the time of day or night, you can count on us to be there
            when you need us most.
          </p>
          <h1>Our Services</h1>
          <div className="our-services-lists">
            <ul className="list-1">
              <li>Residential Water Damage</li>
              <li>Commercial Water Damage</li>
              <li>Structural Drying</li>
              <li>Water Extraction</li>
              <li>Basement Floods</li>
              <li>Content Pack Out and Storage</li>
            </ul>
            <ul className="list-2">
              <li>24 Hour Emergency Services</li>
              <li>Crawlspace Floods</li>
              <li>Direct Insurance Billing</li>
              <li>IICRC</li>
              <li>Free Estimates</li>
              <li>And More!</li>
            </ul>
          </div>
          <p>
            Additionally, we offer free estimates to help you understand the
            scope of the damage and the steps needed for restoration. Our
            transparent pricing and commitment to quality ensure that you
            receive the best possible service without any surprises. Trust E&D
            Restoration for reliable, certified water damage restoration
            services designed to bring your property back to its best.
          </p>
        </div>
        <div className="call-now-img">
          <img src={callNow} alt="callNow"></img>
        </div>
      </div>
    </div>
  );
}

export default Services;
