import aboutImg from "./images/photogallery-img1.jpg";
import "../styles/About.css";

function About() {
  return (
    <div className="about">
      <div className="about-text">
        <h2>About E&D Restoration</h2>
        <p>
          At E&D Restoration, we specialize in providing comprehensive water
          damage restoration services to both residential and commercial clients
          in Richmond. Our dedicated team of professionals is equipped with
          advanced tools and expertise to handle any water damage emergency
          swiftly and effectively. Whether it's a minor leak or a major flood,
          we are committed to restoring your property to its original condition
          with minimal disruption.
        </p>
        <p>
          We understand that water damage can occur at any time, which is why we
          offer 24-hour emergency services to address urgent needs promptly. Our
          goal is to provide peace of mind by delivering high-quality
          restoration services and ensuring your property is safe and dry. Trust
          E&D Restoration to be your reliable partner in navigating the
          challenges of water damage and restoring your space efficiently.
        </p>
        <p>
          Call us any day or time at{" "}
          <a href="tel:(804) 433-5039" style={{ color: "red" }}>
            (804) 433-5039
          </a>{" "}
          to see why we're the team of experts your Richmond friends and
          neighbors trust.{" "}
        </p>
      </div>
      <div className="about-img">
        <img src={aboutImg} alt="aboutImg" height="750"></img>
      </div>
    </div>
  );
}

export default About;
