import "../styles/Contact.css";

function Contact() {
  return (
    <div className="contact" id="about">
      <div className="contact-text">
        <p className="contact-text-p">Contact Us</p>
        <p>
          Call us 24/7 at <a href="tel:(804) 433-5039">(804) 433-5039</a> to
          schedule an appointment now.
        </p>
      </div>
    </div>
  );
}

export default Contact;
