import React, { useState, useEffect } from "react";
import "../styles/HeaderBar.css";
import logo from "./images/EandDlogo.png";
import ico_phone2 from "./images/ico-phone2.png";

const HeaderBar = () => {
  const [isNavbarVisible, setNavbarVisible] = useState(false);
  const [isLargeScreen, setLargeScreen] = useState(window.innerWidth >= 1300);

  // Function to toggle the navbar's visibility
  const toggleNavbar = () => {
    setNavbarVisible(!isNavbarVisible);
  };

  // Listen to window resize and update screen size
  useEffect(() => {
    const handleResize = () => {
      setLargeScreen(window.innerWidth >= 1300);

      // Reset the navbar visibility when the screen becomes large
      if (window.innerWidth >= 1300) {
        setNavbarVisible(false); // No need to toggle navbar in large screen mode
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="header">
        <div className="header-menu">
          <button
            className="menu-button"
            id="nav-button"
            onClick={toggleNavbar}
          >
            <span className="menu-bar"></span>
            <span className="menu-bar"></span>
            <span className="menu-bar"></span>
          </button>
          <a href="#top" className="logo-link">
            <img src={logo} alt="E&D Water Damage and Restoration" />
          </a>
        </div>
        {isLargeScreen && (
          <div className="navbar-container">
            <ul>
              <li>
                <a className="nav-link" href="#services">
                  SERVICES
                </a>
              </li>
              <li>
                <a className="nav-link" href="#locations">
                  LOCATIONS
                </a>
              </li>
              <li>
                <a className="nav-link" href="#contact">
                  CONTACT
                </a>
              </li>
              <li>
                <a className="nav-link" href="#about">
                  ABOUT
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="call-to-action">
          <a href="tel:(804) 433-5039">
            <img src={ico_phone2} alt="phone icon" />
          </a>
          <div className="cta-right">
            <p className="cta-right-text">Call us 24/7 at</p>
            <p className="navbar-tel-text">
              <a
                className="navbar-tel"
                href="tel:(804) 433-5039"
                style={{ color: "#ec2a38" }}
              >
                (804) 433-5039
              </a>
            </p>
          </div>
        </div>
      </div>
      {!isLargeScreen && (
        <div className={`navbar-container ${isNavbarVisible ? "visible" : ""}`}>
          <ul>
            <li>
              <a className="nav-link" href="#services">
                SERVICES
              </a>
            </li>
            <li>
              <a className="nav-link" href="#locations">
                TESTIMONIALS
              </a>
            </li>
            <li>
              <a className="nav-link" href="#contact">
                CONTACT
              </a>
            </li>
            <li>
              <a className="nav-link" href="#about">
                ABOUT
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default HeaderBar;
