import "../styles/Jumbotron.css";
import img1 from "./images/j-image-1.png";
import img2 from "./images/j-image-2.png";
import img3 from "./images/j-image-3.jpg";
import img4 from "./images/j-image-4.png";
import img5 from "./images/j-image-5.png";
import jumbotronCheckmark from "./images/jumbotron-checkmark2.png";

function Jumbotron() {
  const checkmarkHeight = 30;
  return (
    <div className="jumbotron">
      <div className="j-text">
        <p className="j-text-p-1">GET 24/7 WATER DAMAGE RESTORATION HELP</p>
        <p className="j-text-p-2">Get a Free Estimate Today</p>
        <a href="tel:(804) 433-5039">CALL NOW: (804) 433-5039</a>
        <div className="checklist-content" style={{ marginTop: "4%" }}>
          <img
            src={jumbotronCheckmark}
            height={checkmarkHeight}
            alt="checkmark"
          ></img>
          <p>24 Hour Emergency Service</p>
        </div>
        <div className="checklist-content">
          <img
            src={jumbotronCheckmark}
            height={checkmarkHeight}
            alt="Jumbotron Checkmark"
          ></img>
          <p>Free Water Damage Restoration Estimates</p>
        </div>
        <div className="checklist-content">
          <img
            src={jumbotronCheckmark}
            height={checkmarkHeight}
            alt="Jumbotron Checkmark"
          ></img>
          <p>We are Fully Insured for Your Protection</p>
        </div>

        <div className="checklist-content">
          <img
            src={jumbotronCheckmark}
            height={checkmarkHeight}
            alt="Jumbotron Checkmark"
          ></img>
          <p>We'll Work With Your Insurance Company</p>
        </div>
      </div>
      <div className="j-images">
        <div className="j-image">
          <img src={img1} alt="Free Estimate" height="50"></img>
        </div>
        <div className="j-image">
          <img src={img2} alt="24/7 Emergency Service" height="50"></img>
        </div>
        <div className="j-image">
          <img src={img3} alt="24/7 Emergency Service" height="50"></img>
        </div>
        <div className="j-image">
          <img
            className="j-img-4"
            src={img4}
            alt="Certified Experts"
            height="50"
          ></img>
        </div>
        <div className="j-image">
          <img src={img5} alt="Fast & Friendly Service" height="50"></img>
        </div>
      </div>
    </div>
  );
}

export default Jumbotron;
