import "../styles/Checklist.css";
import CheckMark from "./images/checkmark.png";

function Checklist() {
  const checkmarkHeight = 30;

  return (
    <div className="checklist" id="locations">
      <div className="checklist-left">
        <div className="checklist-content">
          <img src={CheckMark} height={checkmarkHeight} alt="Checkmark"></img>
          <p>24 Hour Emergency Service</p>
        </div>
        <div className="checklist-content">
          <img src={CheckMark} height={checkmarkHeight} alt="Checkmark"></img>
          <p>Free Water Damage Restoration Estimates</p>
        </div>
        <div className="checklist-content">
          <img src={CheckMark} height={checkmarkHeight} alt="Checkmark"></img>
          <p>We are Fully Insured for Your Protection</p>
        </div>
      </div>
      <div className="checklist-right">
        <div className="checklist-content">
          <img src={CheckMark} height={checkmarkHeight} alt="Checkmark"></img>
          <p>
            We are Properly Licensed in Order to Perform Water Damage
            Restoration Work
          </p>
        </div>
        <div className="checklist-content">
          <img src={CheckMark} height={checkmarkHeight} alt="Checkmark"></img>
          <p>We'll Work With Your Insurance Company</p>
        </div>
      </div>
    </div>
  );
}

export default Checklist;
