import freeQuote from "./images/freeQuote-icon.png";
import qualified from "./images/qualified-icon.png";
import location from "./images/loaction-icon.png";
import googleIcon from "./images/google-review-icon2.jpg";
import "../styles/ScheduleArea.css";

function ScheduleArea() {
  return (
    <div className="schedule-area">
      <div className="schedule-area-child">
        <img src={freeQuote} height="60" alt="Free Quote"></img>
        <div className="sch-text">
          <p>Free Quote</p>
          <p>for Water Damage Restoration Service</p>
        </div>
      </div>
      <div className="schedule-area-child">
        <img src={qualified} height="60" alt="Qualified"></img>
        <div className="sch-text">
          <p>Qualified</p>
          <p>Licenced. Insured. Experienced</p>
        </div>
      </div>
      <div className="schedule-area-child">
        <img src={location} height="60" alt="Location"></img>
        <div className="sch-text">
          <p>Local</p>
          <p>Locally owned and Operated</p>
        </div>
      </div>
      <div className="customer-review">
        <img src={googleIcon} height="100" alt="google icon"></img>
      </div>
    </div>
  );
}

export default ScheduleArea;
