import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Polygon } from "@react-google-maps/api";
import "../styles/MapComponent.css";

const MapComponent = () => {
  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const center = {
    lat: 37.4, // Richmond, VA latitude
    lng: -77.436, // Richmond, VA longitude
  };

  const APIKEY = "AIzaSyAfY-cBG4jd5lRc2e28Y0IU19JooJg-gDs";

  const [serviceArea, setServiceArea] = useState([]);

  useEffect(() => {
    // Define the coordinates here
    setServiceArea([
      { lat: 37.633567208051, lng: -77.53773795575 },
      { lat: 37.644441943864, lng: -77.444354166687 },
      { lat: 37.537800917839, lng: -77.285052408875 },
      { lat: 37.505125165725, lng: -77.112017740906 },
      { lat: 37.42664499227, lng: -77.090045084656 },
      { lat: 37.328428973176, lng: -77.227374186218 },
      { lat: 37.29129168065, lng: -77.210894694031 },
      { lat: 37.269437646621, lng: -77.021380533875 },
      { lat: 37.131611385974, lng: -77.068072428406 },
      { lat: 37.019853532258, lng: -77.238360514343 },
      { lat: 37.011081245714, lng: -77.315264811218 },
      { lat: 37.052740586725, lng: -77.375689615906 },
      { lat: 37.026432082587, lng: -77.455340494812 },
      { lat: 37.135990689808, lng: -77.521258463562 },
      { lat: 37.111901382371, lng: -77.743731608093 },
      { lat: 37.265066078385, lng: -77.754717936218 },
      { lat: 37.3568157, lng: -77.892047037781 },
      { lat: 37.478974262048, lng: -77.795916666687 },
      { lat: 37.607461348377, lng: -77.729998697937 },
      { lat: 37.550867211461, lng: -77.620135416687 },
      { lat: 37.633567208051, lng: -77.53773795575 }, // Closing the polygon
    ]);
  }, []); // Runs only once when the component mounts

  return (
    <div style={{ position: "relative" }}>
      <LoadScript googleMapsApiKey={APIKEY}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={9}>
          <Polygon
            paths={serviceArea}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2.5,
              fillColor: "#FF0000",
              fillOpacity: 0.4,
            }}
          />
        </GoogleMap>
      </LoadScript>
      <div className="map-text">
        <h3>Water Damage Restoration Service Areas</h3>
        <p>
          We proudly offer comprehensive water damage restoration services
          across a range of locations. If you're unsure whether we serve your
          specific area, please don't hesitate to reach out. We’re here to
          assist you.
        </p>
      </div>
    </div>
  );
};

export default MapComponent;
