import React, { useEffect } from "react";
import img1 from "./images/photogallery-img1.jpg";
import img2 from "./images/photogallery-img2.jpg";
import img3 from "./images/photogallery-img3.jpg";
import img4 from "./images/photogallery-img4.jpg";
import img5 from "./images/photogallery-img5.jpg";
import img6 from "./images/photogallery-img6.jpg";
import "../styles/PhotoGallery.css";

function PhotoGallery() {
  useEffect(() => {
    // Initialize the lightbox when the component is mounted
    if (window.fslightboxInstances) {
      window.fslightboxInstances["gallery"].open();
    }
  }, []);

  return (
    <div className="photo-gallery-container" style={{ textAlign: "center" }}>
      <h1>Photos</h1>
      <a data-fslightbox="gallery" href={img1}>
        <img
          src={img1}
          alt="Image 1"
          style={{ cursor: "pointer", margin: "10px", height: "130px" }}
        />
      </a>
      <a data-fslightbox="gallery" href={img2}>
        <img
          src={img2}
          alt="Image 2"
          style={{ cursor: "pointer", margin: "10px", height: "130px" }}
        />
      </a>
      <a data-fslightbox="gallery" href={img3}>
        <img
          src={img3}
          alt="Image 3"
          style={{ cursor: "pointer", margin: "10px", height: "130px" }}
        />
      </a>
      <a data-fslightbox="gallery" href={img4}>
        <img
          src={img4}
          alt="Image 4"
          style={{ cursor: "pointer", margin: "10px", height: "130px" }}
        />
      </a>
      <a data-fslightbox="gallery" href={img5}>
        <img
          src={img5}
          alt="Image 5"
          style={{ cursor: "pointer", margin: "10px", height: "130px" }}
        />
      </a>
      <a data-fslightbox="gallery" href={img6}>
        <img
          src={img6}
          alt="Image 6"
          style={{ cursor: "pointer", margin: "10px", height: "130px" }}
        />
      </a>
    </div>
  );
}

export default PhotoGallery;
