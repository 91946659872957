import "./styles/App.css";
import Banner from "./components/Banner.js";
import HeaderBar from "./components/HeaderBar.js";
import ScheduleArea from "./components/ScheduleArea.js";
import Services from "./components/Services.js";
import PhotoGallery from "./components/PhotoGallery.js";
import Collapsable from "./components/Collapsable.js";
import MapComponent from "./components/MapComponent.js";
import Checklist from "./components/Checklist.js";
import Jumbotron from "./components/Jumbotron.js";
import Reviews from "./components/Reviews.js";
import Contact from "./components/Contact.js";
import About from "./components/About.js";

function App() {
  return (
    <div className="App">
      <Banner />
      <HeaderBar />
      <Jumbotron />
      <ScheduleArea />
      <Services />
      <PhotoGallery />
      <Collapsable />
      <Checklist />
      <MapComponent />
      <Reviews />
      <Contact />
      <About />
      <div className="footer">
        <h2>E&D Restoration</h2>
        <p>Available 24/7</p>
      </div>
    </div>
  );
}

export default App;
